<template>
  <div
    class="
      relative
      z-30
      flex
      justify-center
      w-full
      px-1
      py-2
      text-white
      lg:px-6 lg:py-3
      bg-brandRed
    "
    :class="['bg-color--' + backgroundColor]"
    :style="[
      textColor !== '' ? { color: textColor } : '',
      backgroundColor !== '' ? { backgroundColor: backgroundColor } : '',
    ]"
  >
    <span class="text-[10px] flex justify-center tracking-widest lg:hidden">{{
      topbarTexts[0].mobileText
    }}</span>
    <span class="hidden text-sm tracking-widest lg:block">{{
      topbarTexts[0].desktopText
    }}</span>
  </div>
</template>

<script>
export default {
  name: 'Topbar',
  props: {
    backgroundColor: {
      type: String,
      required: false,
      default: () => '#000000',
    },
    textColor: {
      type: String,
      required: false,
      default: () => '#FFFFFF',
    },
    topbarTexts: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
}
</script>
